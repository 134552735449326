<template>
  <el-dialog :model-value="props.visible" width="343px" modal-class="dialog-common" @close="emits('close')">
    <!-- header -->
    <template #header v-if="props.header">
      <div 
        class="p-4 flex justify-between items-center"
        :class="[`background_header_${props.header}`]"
      >
        <h4 class="text-[1rem] font-semibold text-white-0" :class="{'!text-black-0': props.header === 'normal'}">{{ props.title }}</h4>
        <button @click="emits('close')">
          <img :src="iconClose" class="size-6">
        </button>
      </div>
    </template>
    <!-- content -->
    <template #default>
      <div class="p-4">
        <slot />
      </div>
    </template>
    <!-- footer -->
    <template #footer v-if="props.footer">
      <common-button :disable="disable" :class="[{'!bg-[#697F91] cursor-not-allowed' : props.grayBtn}]" class="w-full !p-2" @submit="emits('submit')">
        <template #content>
          <div class="flex justify-center items-center gap-2">
            <img v-show="disable" :src="iconsCommon.loadingWhite" class="size-5 animate-spin" >
            <p class="text-white-0 font-semibold text-[1rem]">{{ props.textBtn }}</p>
          </div>
        </template>
      </common-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { iconsCommon } from '@/assets/icons/define_icons'
type BackgroundHeader = '' | 'linear' | 'normal'

interface Props {
  visible: boolean,
  header?: BackgroundHeader,
  title?: string,
  footer?: boolean,
  disable?: boolean | number,
  textBtn?: string,
  textLoadingBtn?: string
  grayBtn: boolean
}
const props = withDefaults(defineProps<Props>(), {
  visible: false,
  footer: true,
  header: '',
  title: '',
  disable: false,
  textBtn: '--',
  textLoadingBtn: '--',
  grayBtn: false
})
const emits = defineEmits(['close', 'submit'])
const iconClose = computed(() => {
  switch(props.header){
    case 'linear':
      return iconsCommon.closeWhite
      break
    case 'normal':
      return iconsCommon.closeGray
    default: 
      return iconsCommon.closeGray
      break
  }
})
</script>

<style lang="scss">
.dialog-common {
  @apply overflow-hidden;
  & > .el-overlay-dialog {
    @apply bg-[#0F4741] bg-opacity-20 backdrop-blur-lg overflow-hidden flex items-center justify-center;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  .el-dialog{
    @apply rounded-[0.75rem] p-0 overflow-hidden m-0 mx-2;
    .el-dialog__headerbtn {
      @apply hidden;
    }
    .el-dialog__header.show-close{
      @apply p-0;
    }
    .el-dialog {
      @apply p-0;
    }
    .el-dialog__footer{
      @apply flex p-2;
    }
  }
  .background_header_{
    &linear{
      @apply bg-[linear-gradient(90deg,#0054A1_0%,#66E0D9_100.31%)];
    }
    &normal{

    }
  }
}
</style>
